import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../API/axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

export default function Table() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [jumpToPage, setJumpToPage] = useState("");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [registrar, setRegistrar] = useState('');
  const [category, setCategory] = useState('');
  const [registrars, setRegistrars] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const params = {};
      if (startDate) params.start_date = format(startDate, 'yyyy-MM-dd');
      if (endDate) params.end_date = format(endDate, 'yyyy-MM-dd');
      if (registrar) params.registrar = registrar;
      if (category) params.category = category;

      const response = await axios.get('/api/v1/getdata', { params });
      setData(response.data);
      setPages(response.data.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [startDate, endDate, registrar, category]);

  const fetchRegistrars = async () => {
    try {
      const response = await axios.get('/api/v1/getregistrars');
      setRegistrars(response.data);
    } catch (error) {
      console.error('Error fetching registrars:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/v1/getcategories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchRegistrars();
    fetchCategories();
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(pages / 10)) {
      setCurrentPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(pages / 10);
  const startIndex = (currentPage - 1) * 10;
  const currentPageData = data.slice(startIndex, startIndex + 10);

  const exportToCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'table_data.csv');
  };

  return (
    <div>
      <div className="date-picker-container flex flex-col sm:flex-row justify-between items-center bg-white px-8 rounded-md py-4 shadow-default">
        <div className="date-picker flex items-center mr-4">
          <label className="text-gray-700 mr-2">From</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="dd-mm-yyyy"
            className="py-1 px-1 w-[150px] border border-slate-400 rounded-md"
          />
        </div>
        <div className="date-picker flex items-center mr-4">
          <label className="text-gray-700 mr-2">To</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd-MM-yyyy"
            placeholderText="dd-mm-yyyy"
            className="py-1 px-1 w-[150px] border border-slate-400 rounded-md"
          />
        </div>
        <div className="dropdown-container flex items-center mr-4">
          <label className="text-gray-700 mr-2">Registrar</label>
          <select
            value={registrar}
            onChange={(e) => setRegistrar(e.target.value)}
            className="py-1 px-2 border border-slate-400 rounded-md"
          >
            <option value="">All</option>
            {registrars.map((reg, index) => (
              <option key={index} value={reg}>{reg}</option>
            ))}
          </select>
        </div>
        <div className="dropdown-container flex items-center mr-4">
          <label className="text-gray-700 mr-2">Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="py-1 px-2 border border-slate-400 rounded-md"
          >
            <option value="">All</option>
            {categories.map((cat, index) => (
              <option key={index} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div className="export-button-container ml-auto">
          <button
            onClick={exportToCSV}
            className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            Export
          </button>
        </div>
      </div><br/>

      <div className="w-full rounded bg-white overflow-hidden shadow-default">
        <div className="overflow-x-auto w-full">
          <table className="text-sm min-w-full text-gray-500 text-center">
            <thead className="text-[18px] w-full shadow-default bg-primary-600 text-white">
              <tr>
                <th className="px-2 py-2 w-[250px]">Domain Name</th>
                <th className="px-2 py-2 w-[250px]">Date Detected</th>
                <th className="px-2 py-2 w-[250px]">Registrar</th>
                <th className="px-2 py-2 w-[250px]">Registration Date</th>
                <th className="px-2 py-2 w-[250px]">Category</th>
                <th scope="col" className="px-2 py-3"><span/></th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => (
                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                  <td className="px-6 py-4 text-[16px] text-gray-900 whitespace-nowrap">{item.domain_name}</td>
                  <td className="px-6 py-4 text-[16px] text-gray-900 whitespace-nowrap">{item.date_detected}</td>
                  <td className="px-6 py-4 text-[16px] text-gray-900 whitespace-nowrap">{item.registrar}</td>
                  <td className="px-6 py-4 text-[16px] text-gray-900 whitespace-nowrap">{item.registration_date}</td>
                  <td className="px-6 py-4 text-[16px] text-gray-900 whitespace-nowrap">{item.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
      </div>

      <div className="pagination-container flex flex-col sm:flex-row justify-between items-center pb-2 rounded-md py-2">
        <div className="pagination-buttons flex items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 rounded border bg-white border-gray-200 py-2 hover:scale-110 duration-150 ${currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
            </svg>
          </button>
          <span className="px-1 text-sm text-gray-700">Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-2 rounded border bg-white border-gray-200 py-2 hover:scale-110 duration-150 ${currentPage === totalPages ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <svg className="w-3 h-3 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
            </svg>
          </button>
        </div>
        <div className="jump-to-page-container mt-2 sm:mt-0 flex items-center">
          <input
            type="number"
            placeholder="Page"
            value={jumpToPage}
            onChange={(e) => setJumpToPage(e.target.value)}
            className="py-2 px-2 border border-gray-300 rounded-md"
          />
          <button
            onClick={handleJumpToPage}
            className="ml-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
          >
            Go
          </button>
        </div>
      </div>
    </div>
  );
}
