import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import axios from '../API/axios';

export default function ProtectedRoutes() {
    const [auth, setAuth] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get("/api/v1/auth/state", { withCredentials: true })
          .then(function (response) {
              if (response.data.message === 'user is authenticated' && response.status === 200) {  
                  setAuth(true);
              } else {
                  setAuth(false);
              }
          })
          .catch(function (error) {
              setAuth(false);
          });
    }, [navigate]);

    if (auth === null) {
        return null; // Or a loading spinner
    }

    return auth ? <Outlet /> : <Navigate to='/' />;
}
