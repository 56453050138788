import React, { useState } from 'react';
import axios from '../../API/axios';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(true);

    const handleLogout = (e) => {
        e.preventDefault();
        axios.post('/api/v1/logout/user', 
            {msg: 'yes sure'}, 
            { withCredentials: true })
        .then(function(response) {
            if (response.data.message === "you have logged out") {
                navigate('/');
            }
        })
        .catch(function(error) {
            if (error.response) {
                if (error.response.status === 401) {
                    alert("unauthorized access");
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        });
    };

    const handleCancel = () => {
        setShowPopup(false);
        navigate('/dashboard');
    }

    if (!showPopup) return null;

    return (
        <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-slate-300 bg-opacity-80">
            <div className="max-h-full w-full max-w-xl overflow-y-auto p-6 rounded-lg shadow-2xl bg-white">
                <div className="flex">
                    <div>
                        <svg className="w-7 h-7 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                    </div>
                    <div className="ml-3">
                        <p className="mt-1 text-gray-600 leading-relaxed">Logging out of Malicious Domains - Are you sure?</p>
                    </div>
                </div>
                <div className="flex justify-end items-center mt-5">
                    <button onClick={handleCancel} className="px-6 py-2 bg-transparent border border-gray-300 text-gray-800 text-sm font-medium rounded-md">
                        Cancel
                    </button>
                    <button onClick={handleLogout} className="px-4 py-2 ml-2 bg-red-500 hover:bg-red-600 text-white text-sm font-medium rounded-md">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
}
