import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Auth/login/login';
import Layout from './components/Layouts/layout';
import Dashboard from './components/sidebaritems/Dashboard/dashboard';
import Settings from './components/Settings/settings';
import Table from './components/sidebaritems/Dashboard/table';
import Logout from './components/Auth/logout/logout';
import Search from './components/sidebaritems/Dashboard/search';
import Pagenotfound from './components/common/Servererror/Pagenotfound';
import ProtectedRoutes from './components/utils/protectedroutes';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Login/>} />
      <Route element={<ProtectedRoutes/>}>
      <Route path='/dashboard' element={<Layout><Dashboard/></Layout>} />
      <Route path='/settings' element={<Layout><Settings/></Layout>} />
      <Route path='/table' element={<Layout><Table/></Layout>} />
      <Route path='/search' element={<Layout><Search/></Layout>} />
      <Route path='/logout' element={<Logout/>} />
      </Route>
      <Route path='/*' element={<Pagenotfound/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
