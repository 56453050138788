import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


function IsActiveRoute(routeName) {
  const location = useLocation();
  const currentPath = location.pathname;
  return currentPath.startsWith(routeName);
}

export default function Sidebar() {


  return (
    <aside className="border-r border-gray-200 shadow-xl transform -translate-x-2 bg-white left-0 w-64 h-full flex flex-col justify-between">
      <div className="h-full overflow-y-auto py-4 px-4 overflow-hidden">
      <ul className="space-y-2 font-medium">
                

                  {/* Dashbaord */}
                  <li className={IsActiveRoute('/dashboard') ? 'rounded-lg bg-blue-600 text-white ' : '  text-gray-700'}>
                    <Link to='/dashboard' className="mt-2 flex items-center px-4 md:px-6 py-2   hover:bg-blue-600 hover:text-white  hover:rounded-lg group">
                      <svg className={`transition duration-75 group-hover:text-white -ml-1 ${IsActiveRoute('/dashboard') && 'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M13.5 2c-.178 0-.356.013-.492.022l-.074.005a1 1 0 0 0-.934.998V11a1 1 0 0 0 1 1h7.975a1 1 0 0 0 .998-.934l.005-.074A7.04 7.04 0 0 0 22 10.5 8.5 8.5 0 0 0 13.5 2Z"/>
                        <path d="M11 6.025a1 1 0 0 0-1.065-.998 8.5 8.5 0 1 0 9.038 9.039A1 1 0 0 0 17.975 13H11V6.025Z"/>
                      </svg>
                      <span className='ml-4'>Home</span>
                    </Link>
                </li>


                {/* Report */}
                <li className={IsActiveRoute('/search') ? 'rounded-lg bg-blue-600 text-white ' : 'text-gray-700'}>
                    <Link to='/search' className="mt-5 flex items-center px-4 md:px-6 py-2 hover:bg-blue-600 hover:text-white hover:rounded-lg group">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24" height="24"><path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/></svg>

                        <span className='ml-4'>Search</span>
                    </Link>
                </li>

                

                 {/* Report */}
                <li className={IsActiveRoute('/table') ? 'rounded-lg bg-blue-600 text-white ' : 'text-gray-700'}>
                    <Link to='/table' className="mt-5 flex items-center px-4 md:px-6 py-2 hover:bg-blue-600 hover:text-white hover:rounded-lg group">
                    <svg className={`transition duration-75 group-hover:text-white -ml-1 ${IsActiveRoute('/table') && 'text-white'}`}  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm-1 9a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Zm2-5a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm4 4a1 1 0 1 0-2 0v3a1 1 0 1 0 2 0v-3Z" clipRule="evenodd"/>
                      </svg>

                        <span className='ml-4'>Reports</span>
                    </Link>
                </li>

                 
                  

               {/* Settings */}
                <li className={IsActiveRoute('/settings') ? 'rounded-lg bg-blue-600 text-white' : 'text-gray-700'}>
                    <Link to='/settings' className="mt-5 flex items-center px-4 md:px-6 py-2 hover:bg-blue-600 hover:text-white hover:rounded-lg group">
                    <svg className={`transition duration-75 group-hover:text-white -ml-1 ${IsActiveRoute('/settings') && 'text-white'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path fillRule="evenodd" d="M9.586 2.586A2 2 0 0 1 11 2h2a2 2 0 0 1 2 2v.089l.473.196.063-.063a2.002 2.002 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.827l-.063.064.196.473H20a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-.089l-.196.473.063.063a2.002 2.002 0 0 1 0 2.828l-1.414 1.414a2 2 0 0 1-2.828 0l-.063-.063-.473.196V20a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-.089l-.473-.196-.063.063a2.002 2.002 0 0 1-2.828 0l-1.414-1.414a2 2 0 0 1 0-2.827l.063-.064L4.089 15H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h.09l.195-.473-.063-.063a2 2 0 0 1 0-2.828l1.414-1.414a2 2 0 0 1 2.827 0l.064.063L9 4.089V4a2 2 0 0 1 .586-1.414ZM8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" clipRule="evenodd"/>
                    </svg>
                    <span className='ml-4'>Settings</span>
                    </Link>
                </li>

                {/* logout */}
                <li className={IsActiveRoute('/logout') ? 'rounded-lg bg-blue-600 text-white' : 'text-gray-700'}>
                    <Link to='/logout' className="mt-5 flex items-center px-4 md:px-6 py-2 hover:bg-blue-600 hover:text-white hover:rounded-lg group">
                    <svg className="mr-2 w-4 h-4"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/></svg>
                    <span className='ml-4'>Logout</span>
                    </Link>
                </li>
            </ul>
        </div>

    </aside>

  )
}
