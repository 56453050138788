import React, { useState, useEffect } from 'react';
import Stats from './stats';
import Barchart from './barchart';
import Piechart from './piechart';
import Dropdown from './dropdown';

export default function Dashboard() {
  const [range, setRange] = useState('24h');
  const [startDate, setStartDate] = useState(null);
  const [endDate] = useState(getFormattedDate(new Date()));

  useEffect(() => {
    let newStartDate;

    switch (range) {
      case '1h':
        newStartDate = new Date(Date.now() - 3600 * 1000);
        break;
      case '1w':
        newStartDate = new Date(Date.now() - 7 * 24 * 3600 * 1000);
        break;
      case '1m':
        newStartDate = new Date(Date.now() - 30 * 24 * 3600 * 1000);
        break;
      case '24h':
      default:
        newStartDate = new Date(Date.now() - 24 * 3600 * 1000);
        break;
    }

    setStartDate(getFormattedDate(newStartDate));
  }, [range]);

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  return (
    <section className='w-full h-full space-y-4'>
      <Dropdown range={range} setRange={setRange} />
      <Stats startDate={startDate} endDate={endDate} />
      <div className='flex w-full h-[450px] gap-3'>
        <div className='w-[65%] h-full'>
          <Barchart startDate={startDate} endDate={endDate} />
        </div>
        <div className='w-[35%] h-full'>
          <Piechart startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </section>
  );
}
