import React, { useEffect, useState } from 'react';
// import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import axios from '../../API/axios';

export default function Barchart({ startDate, endDate }) {
  const [chartOptions, setChartOptions] = useState({
    series: [{
      name: 'Count',
      data: []
    }],
    chart: {
      type: 'bar',
      height: 380
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'flat'
      }
    },
    xaxis: {
      categories: []
    },
    fill: {
      colors: ['#A0522D']
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get('/api/v1/count/category', {
          params: { start_date: startDate, end_date: endDate }
        });
        const data = response.data;

        if (data && Array.isArray(data)) {
          const categories = data.map(item => item.category);
          const counts = data.map(item => item.count);

          setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [{ name: 'Count', data: counts }],
            xaxis: { categories }
          }));
        } else {
          console.error('Data is not in expected format');
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [startDate, endDate]);

  return (
    <div className="h-full bg-white rounded-md shadow-default">
      <h5 className="text-xl font-bold text-gray-600 py-2.5 pl-5">Count By Category</h5>
      <hr className="border-slate-300" />
      <div className="py-4">
        <Chart options={chartOptions} series={chartOptions.series} type="bar" height={380} />
      </div>
    </div>
  );
}
