import React, { useState } from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar';

export default function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='w-screen h-screen overflow-hidden flex flex-col'>
      <div>
        <Navbar toggleSidebar={toggleSidebar} />
      </div>
      <div className='w-full flex h-full overflow-hidden flex-grow bg-slate-100'>
        <div
          className={`${
            isSidebarOpen ? 'translate-x-0 -mr-2' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out`}
        >
          {isSidebarOpen && <Sidebar />}
        </div>
        <div className='w-full flex-grow overflow-y-auto p-3'>
          {children}
        </div>
      </div>
    </div>
  );
}
