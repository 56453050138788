import React from 'react'
import { Link } from 'react-router-dom';


export default function Navbar({ toggleSidebar }) {

    return (
        <header className="antialiased">
            <nav className="bg-white w-full border-gray-200 shadow border-b pl-3 py-2.5">
            <div className="flex flex-wrap justify-between items-center">
                <div className="flex justify-start items-center -ml-1 ">
                    <button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" onClick={toggleSidebar} className="hidden p-2 mr-3 rounded cursor-pointer lg:inline text-gray-900 bg-gray-200 border border-gray-300">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeWidth="2" d="M1 1h14M1 6h14M1 11h14"/> </svg>
                    </button>
                    <Link to='/dashboard' className="flex mr-4">
                       
                        <span className="self-center text-[28px] font-semibold whitespace-nowrap">
                            Malicious Domains
                        </span>
                    </Link>
                </div>
                  
                {/* <div className="flex items-center gap-6">
                    
                    
                    {iscontactusHovered && (
                        <div role="tooltip" className="absolute z-50 inline-block top-12 right-6 px-4 py-2 text-sm font-medium text-white bg-gray-900 rounded-md shadow-sm opacity-100 tooltip dark:bg-gray-700">
                            Contact Us
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    )}
                    <Link to='/contactus' target="_blank" onMouseEnter={() => setIsContactUsHovered(true)} onMouseLeave={() => setIsContactUsHovered(false)} className="rounded-lg text-gray-900 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
                        <svg className="w-6 h-6 text-gray-700 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                    </Link>
                    
                    
                    <div className='relative pr-3' ref={dropdownRef}>
                        <button type="button" className="flex  text-sm bg-gray-800 rounded-full md:mr-0 ring-4 ring-gray-300 " id="user-menu-button" aria-expanded={isOpen}  onClick={toggleDropdown} title="View Profile">
                            <div className="w-8 h-8 uppercase rounded-full bg-gray-600 text-white font-bold text-lg flex justify-center items-center">
                              {email.charAt(0)}
                            </div>
                        </button>
                        {isOpen && (
                            <div className="absolute right-4 z-50  border border-gray-200 mt-2 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:border-gray-500 dark:divide-gray-600" id="dropdown">
                                <div className="py-3 px-4 text-start">
                                
                                    <span className="block text-sm font-semibold text-gray-500 truncate dark:text-gray-200">{email}</span>
                                </div>
                                <ul className="py-1 cursor-pointer text-gray-500 dark:text-gray-200" aria-labelledby="dropdown">
                                    <li>
                                        <div onClick={() => handleMenuItemClick('/settings/profile')} className="flex items-center py-2 px-4 text-sm hover:bg-blue-100 hover:text-blue-500 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <svg className="w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/></svg>
                                            My Profile
                                        </div>
                                    </li>
                                    {isOpen&& (
                                        <li>
                                            <div onClick={() => handleMenuItemClick('/settings')} className="flex items-center py-2 px-4 text-sm hover:bg-blue-100 hover:text-blue-500 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <svg className="w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"/></svg>
                                                Account Settings
                                            </div>
                                        </li>
                                    )}
                                    <li>
                                        <div onClick={() => handleMenuItemClick('/settings/subscription')} className="flex justify-between items-center py-2 px-4 text-sm hover:bg-blue-100 hover:text-blue-500 dark:hover:bg-gray-600 dark:hover:text-white">
                                            <span className="flex items-center">
                                                <svg className="mr-3 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="m7.164 3.805-4.475.38L.327 6.546a1.114 1.114 0 0 0 .63 1.89l3.2.375 3.007-5.006ZM11.092 15.9l.472 3.14a1.114 1.114 0 0 0 1.89.63l2.36-2.362.38-4.475-5.102 3.067Zm8.617-14.283A1.613 1.613 0 0 0 18.383.291c-1.913-.33-5.811-.736-7.556 1.01-1.98 1.98-6.172 9.491-7.477 11.869a1.1 1.1 0 0 0 .193 1.316l.986.985.985.986a1.1 1.1 0 0 0 1.316.193c2.378-1.3 9.889-5.5 11.869-7.477 1.746-1.745 1.34-5.643 1.01-7.556Zm-3.873 6.268a2.63 2.63 0 1 1-3.72-3.72 2.63 2.63 0 0 1 3.72 3.72Z"/></svg>
                                                Subscription
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="py-1 text-gray-500 dark:text-gray-500" aria-labelledby="dropdown">
                                    <li>
                                        <div onClick={handleLogout} className="cursor-pointer flex items-center py-2 px-4 text-sm hover:bg-blue-100 hover:text-blue-500 dark:hover:bg-gray-600 dark:text-gray-200">
                                            <svg className="w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/></svg>
                                            Logout
                                        </div>
                                    </li>
                                </ul>
                            </div>
                         )}

                    </div>
                </div>  */}
            </div>
      </nav>
        </header>
  )

}