import React, { useState, useRef, useEffect } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import axios from '../../API/axios';
import ErrorToast from "../../common/Tosts/Errortost";
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCAPTCHA

export default function Login() {
    const [passwordIcon, setPasswordIcon] = useState(<IoEyeOffOutline />);
    const [passwordType, setPasswordType] = useState('password');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isErrorToast, setIsErrorToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const recaptchaRef = useRef(null); // reCAPTCHA reference
    const navigate = useNavigate();

    const handleToggle = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            setPasswordIcon(<IoEyeOutline />);
        } else {
            setPasswordType('password');
            setPasswordIcon(<IoEyeOffOutline />);
        }
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setIsErrorToast(true);
        setTimeout(() => {
            setIsErrorToast(false);
        }, 5000);
    };

    useEffect(() => {
        axios.get("/api/v1/auth/state", { withCredentials: true })
            .then(function (response) {
                if (response.data.message === 'user is authenticated' && response.status === 200) {  
                    navigate('/dashboard');
                }
            })
            .catch(function (error) {
                // Handle errors if needed
            });
    }, [navigate]);

    const handleSignIn = (e) => {
        e.preventDefault();
        if (recaptchaRef.current) {
            recaptchaRef.current.execute(); // Trigger reCAPTCHA
        }
    };

    const onRecaptchaChange = (token) => {
        if (!token) {
            showErrorToast("reCAPTCHA verification failed. Please try again.");
            return;
        }
        axios.post('/api/v1/login', {
            email: email,
            password: password,
            recaptcha_token: token
        }, { withCredentials: true })
        .then(function(response) {
            if (response.data.message === "Login successful") {
                setEmail('');
                setPassword('');
                navigate('/dashboard');
            } 
        })
        .catch(function(error) {
            if (error.response) {
                if (error.response.status === 401) {
                    showErrorToast("Invalid credentials!");
                } else {
                    showErrorToast("An error occurred. Please try again.");
                }
            }
        });
    };

    return (
        <>
            <section className="flex items-center justify-center bg-slate-50 bg-cover bg-center bg-no-repeat w-screen h-screen">
                <div className="w-[27%] bg-white rounded-lg shadow-2xl pb-4">
                    <div className="p-6 sm:p-8">
                        <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Sign in
                        </h1>
                        <form className="space-y-5 mt-6" onSubmit={handleSignIn}>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                                    Email
                                </label>
                                <div className="relative">
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <svg className="w-7 h-7 text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        id="email"
                                        className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                        placeholder="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoFocus
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                                    Password
                                </label>
                                <div className="relative">
                                    <span className="absolute right-3 top-3 w-5 h-5 cursor-pointer" onClick={handleToggle}>{passwordIcon}</span>
                                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <span>
                                            <svg className="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <input
                                        type={passwordType}
                                        id="password"
                                        className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                        placeholder="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="on"
                                        required
                                    />
                                </div>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeUCfMpAAAAAOdS-EkrbRnBiiJxGyiuUxzUJrA1"
                                size="invisible"
                                onChange={onRecaptchaChange}
                            />
                            <button type="submit" className="w-full text-white mt-4 bg-primary-500 hover:bg-primary-700 focus:outline-none font-medium rounded-lg text-sm px-5 py-3 text-center">
                                Sign in
                            </button>
                        </form>   
                    </div>
                </div>
                {isErrorToast && (
                    <ErrorToast toastMessage={toastMessage} onClose={() => setIsErrorToast(false)} />
                )}
            </section>
            <footer className="bg-gray-800 fixed bottom-0 left-0 w-full z-50 py-2">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center text-xs text-gray-300">
                        <Link to="https://shreshtait.com/" target="_blank" rel="noopener noreferrer">© 2024 Shreshta IT Technologies Pvt. Ltd. All rights reserved. </Link>
                    </div>
                </div>
            </footer>
        </>
    );
}
