import React from 'react';

export default function Dropdown({ range, setRange }) {
  const handleChange = (event) => {
    setRange(event.target.value);
  };

  return (
    <div className="bg-white rounded-md shadow-default p-4 mb-4 flex space-x-4 justify-end">
      
      <select
        id="time-range"
        value={range}
        onChange={handleChange}
        className="border border-gray-300 rounded-md p-2"
      >
        <option value="24h">Last 24 Hours</option>
        <option value="1h">Last 1 Hour</option>
        <option value="1w">Last 1 Week</option>
        <option value="1m">Last 1 Month</option>
      </select>
    </div>
  );
}
