import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Profile from './profile/profile';
import Changepassword from './change password/changepassword';

export default function Settings() {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('profile');
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const tab = params.get('tab');
      if (tab) {
        setActiveTab(tab);
      }
    }, [location.search]);
  
    const handleTabClick = (tab) => {
      setActiveTab(tab);
      navigate(`/settings?tab=${tab}`);
    };

    const renderTabContent = () => {
      switch(activeTab) {
        case 'profile':
          return <Profile />;
        case 'changepassword':
          return <Changepassword />;
        default:
          return null;
      }
    };

    return (
        <section className="w-full h-full ">

                    <div className="rounded-md px-6 shadow-default w-full bg-white">
                    <h1 className="border-b  border-gray-400/40 p-3 pl-5 text-2xl font-semibold">Settings</h1>
                    <div className="flex">
                      <div className="border-r h-[627px] hidden sm:block w-[15%] px-1 pb-4">
                        <ul>
                          <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'profile' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('profile')}>Profile</li>
                          {/* <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'changeemail' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('changeemail')}>Email</li> */}
                          <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'changepassword' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('changepassword')}>Password</li>
                          {/* <li className={`mt-5 cursor-pointer border-l-2 border-transparent px-4 py-2 font-semibold transition ${activeTab === 'twofactor' ? 'border-l-primary-600 text-primary-500 bg-primary-200/10' : 'hover:border-l-primary-500 hover:bg-primary-200/10 hover:text-primary-500'}`} onClick={() => handleTabClick('twofactor')}>Security</li> */}
                        </ul>
                      </div>
                      <div className="flex-1">
                        {renderTabContent()}
                      </div>
                    </div>
                  </div>
           </section>
        );

}
