import React, { useEffect, useState } from 'react';
// import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import axios from '../../API/axios';

export default function Piechart({ startDate, endDate }) {
  const [chartOptions, setChartOptions] = useState({
    series: [],
    chart: {
      type: 'pie',
      height: 400
    },
    labels: [],
    legend: {
      position: 'bottom',
      fontFamily: 'Inter, sans-serif'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      }
    }
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get('/api/v1/count/category', {
          params: { start_date: startDate, end_date: endDate }
        });
        const data = response.data;

        if (data && Array.isArray(data)) {
          const categories = data.map(item => item.category);
          const counts = data.map(item => item.count);

          setChartOptions(prevOptions => ({
            ...prevOptions,
            series: counts,
            labels: categories
          }));
        } else {
          console.error('Data is not in expected format');
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [startDate, endDate]);

  return (
    <div className="h-full bg-white rounded-md shadow-default">
      <h5 className="text-xl font-bold text-gray-600 py-2.5 pl-5">Count By Threat</h5>
      <hr className="border-slate-300" />
      <div className="py-4">
        <Chart options={chartOptions} series={chartOptions.series} type="pie" height={400} />
      </div>
    </div>
  );
}
